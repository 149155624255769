import React from 'react'

export default class TrackCoverPlane extends React.Component {
  state = {
    selected: false,
    touched: false,
  }

  longTouchTime = 1000;

  touch() {
    const { data } = this.props;
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'touchTrack', data }))
    }
    this.setState({ touched: true });
  }

  untouch() {
    this.setState({ touched: false });
  }

  onLongTouch() {
    const { data } = this.props;
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'longTouchTrack', data }))
    }
    this.setState({ selected: true });
  }

  select() {
    this.setState({ selected: true });
  }

  deselect() {
    this.setState({ selected: false })
  }

  render() {
    const {position, size, texture, mask, data, meshRef } = this.props;
    const { touched } = this.state;
    const actualSize = size;
    const opacity = touched ? 1 : 0.8;
    return (
      <mesh
        ref={meshRef}
        position={position}
        data={data}
        touch={() => this.touch()}
        untouch={() => this.untouch()}
        onLongTouch={() => this.onLongTouch()}
      >
        <planeGeometry attach="geometry" args={[1 * actualSize, 1 * actualSize, 1 * actualSize]} />
        <meshBasicMaterial attach="material" alphaMap={mask} transparent={true} opacity={opacity}  map={texture} />
      </mesh>
    )
  }
  
}