import React, { useRef, Suspense } from 'react'
import * as THREE from 'three';
import { TOUCH, Vector3 } from 'three';
import { Canvas, useFrame, useThree, extend } from 'react-three-fiber'
import { OrbitControls } from './plugins/OrbitControls'
import Particles from './meshes/Particles';
import Sparks from './meshes/Sparks';
import WaterBubblePlane from './meshes/WaterBubblePlane';
import TrackCoverPlane from './meshes/TrackCoverPlane';
import Bloom from './effects/Bloom';

const pages = {
  DISCOVERY: 'Discovery',
  COLLECTION: 'Collection',
  COLLECTION_OTHER: 'CollectionOther',
}
const pageConfig = {
  Discovery: {
    canMoveTracks: false,
    displacement: 4,
    randomSpace: 1,
    numberOfSides: 5,
  },
  Collection: {
    canMoveTracks: true,
    displacement: 2,
    randomSpace: 1,
  },
  CollectionOther: {
    canMoveTracks: false,
    displacement: 2,
    randomSpace: 1
  }
}
let currentPage, currentPageConfig;
let orbitControlsRef;
let scene, mainCamera, lastIntersect, lastIntersectDeltaPos, followTarget;
let enableCameraPan;

function setPage(_currentPage) {
  currentPage = _currentPage;
  currentPageConfig = pageConfig[currentPage];
}

extend({ OrbitControls })
function Controls(props) {
  const { gl, camera } = useThree()
  const ref = orbitControlsRef = useRef()
  useFrame(() => {
    ref.current.update()
    ref.current.enablePan = enableCameraPan;

  })
  return <orbitControls ref={ref} args={[camera, gl.domElement]} target={[0, 13, 0]}{...props} />
}

export const manager = new THREE.LoadingManager();

export function moveRef(ref, position) {
  if (ref.current.position && currentPageConfig.canMoveTracks) {
    const moveLerpFactor = 0.1;
    ref.current.position.x = THREE.MathUtils.lerp(ref.current.position.x, position.x, moveLerpFactor);
    ref.current.position.y = THREE.MathUtils.lerp(ref.current.position.y, position.y, moveLerpFactor);
    ref.current.position.z = THREE.MathUtils.lerp(ref.current.position.z, position.z, moveLerpFactor);
  }
}

function Track(props) {
  const ref = useRef()
  useFrame(() => {
    moveRef(ref, props.position)
  })

  return (<Suspense fallback={null}>
    <TrackCoverPlane
      meshRef={ref}
      position={props.position}
      size={props.size}
      rotation={props.rotation}
      texture={props.texture}
      mask={props.mask}
      data={props.data}
    />
  </Suspense>)
}

function Main({ children }) {
  scene = useRef()
  const { gl, camera } = useThree()
  mainCamera = camera;
  useFrame(() => {
    if (followTarget) {
      var targetPos = new THREE.Vector3(followTarget.position.x, followTarget.position.y, mainCamera.position.z)
      var target = mainCamera.position.lerp(targetPos, 0.1);
      mainCamera.position.x = target.x;
      mainCamera.position.y = target.y;
      orbitControlsRef.current.target.x = target.x;
      orbitControlsRef.current.target.y = target.y;
    }
    void ((gl.autoClear = false), gl.clearDepth(), gl.render(scene.current, camera))
  }, 4)
  return <scene ref={scene}>{children}</scene>
}

class App extends React.Component {
  longTouchTime = 1000;
  colorChoices = ['#D4DC80', '#172553', '#54B5FF', '#EAB881', '#E33D38', '#0069C0', '#FF6080', '#EF5F4C', '#B974B5', '#FCD026', '#254D2A', '#A2A4EA', '#FF532A', '#3654C4', '#B83AB8', '#FF8000', '#79C809']

  state = {
    loaded: false,
    library: [],
    libraryMultiply: 1,
    textures: {}
  };
  componentDidMount() {
    if (window.ReactNativeWebView) {
      window.addEventListener("message", event => this.receiveMessage(event), false);
      this.sendMessage({ type: 'init', message: 'initialization successful'});
    }
  }


  initManager() {
    manager.onLoad = () => {
      this.sendMessage({ type: 'init-orbs', message: 'orbs loaded' });
      this.setState({ loaded: true })
    };
  }

  generateGridSequentialX(numberOfTracks, sequence) {
    if (!sequence) {
      sequence = [2, 7, 4, 10, 1, 7, 11, 4, 7, 2, 10, 5]
    }
    const positions = [];
    for (let index = 0; index < numberOfTracks; index++) {
      const currentX = sequence[index % sequence.length];
      const currentY = -index;
      positions.push(new THREE.Vector3(currentX, currentY))
    }
    return {
      positions,
    }
  }

  generateGridPoly(numberOfTracks, numberOfSides = 4) {
    var remainingTracks = numberOfTracks - 1;
    var currentDisplacement = new THREE.Vector3();
    var currentSideLength = 1;
    var positions = [currentDisplacement];
    while (remainingTracks > 0) {
      for (let i = 0; i < numberOfSides; i++) {
        const angle = i * 2 * Math.PI / numberOfSides;
        const nextAngle = (i + 1) * 2 * Math.PI / numberOfSides;
        for (let j = 0; j < currentSideLength; j++) {
          const angleVector = new THREE.Vector3(Math.cos(angle), Math.sin(angle))
          const nextAngleVector = new THREE.Vector3(Math.cos(nextAngle), Math.sin(nextAngle))
          const currentDisplacement = new THREE.Vector3();
          currentDisplacement.add(angleVector.multiplyScalar(currentSideLength - j));
          currentDisplacement.add(nextAngleVector.multiplyScalar(j));
          remainingTracks--;
          positions.push(currentDisplacement);
        }
      }
      currentSideLength++;
    }
    return {
        positions,
    };
  }

  setObjPosition(obj, { generateIndex } = { generateIndex: false }) {
    if (generateIndex) {
      obj.index = this.state.libaryWithPositions.findIndex(track => track === obj);
    }
    if (!obj.position) {
      obj.position = Object.assign(new THREE.Vector3(), this.generatedGrid.positions[obj.index]);
      const { displacement, randomSpace } = currentPageConfig;
      obj.position.multiplyScalar(displacement).addScalar((Math.random() - 0.5) * randomSpace);
    } else {
      obj.position = Object.assign(new THREE.Vector3(), obj.position);
    }
    obj.originalPosition = Object.assign({}, obj.position);
  }

  loadTextures() {
    const { library, textures } = this.state;
    this.orbMaskTexture = new THREE.TextureLoader(manager).load(textures.orbMask);
    this.orbMaskTexture.minFilter = THREE.LinearFilter;
    const orbTexture = new THREE.TextureLoader(manager).load(textures.orb)
    orbTexture.premultiplyAlpha = true;
    orbTexture.minFilter = THREE.LinearFilter;
    if (currentPage === pages.DISCOVERY) {
      this.generatedGrid = this.generateGridPoly(library.length, currentPageConfig.numberOfSides);
    } else if (currentPage === pages.COLLECTION) {
      this.generatedGrid = this.generateGridSequentialX(library.length);
    } else if (currentPage === pages.COLLECTION_OTHER) {
      this.generatedGrid = this.generateGridSequentialX(library.length);
    }
    const libaryWithPositions = library.map((obj, i) => {
      obj.index = i;
      obj.size = 1.8 + Math.random() * 0.4;
      obj.rotation = (0.5 - Math.random()) * Math.PI;
      obj.color = obj.color ? obj.color : this.colorChoices[Math.floor(Math.random() * this.colorChoices.length)];
      obj.mask = this.orbMaskTexture;
      this.setTrack(obj, i);
      obj.texture.minFilter = THREE.LinearFilter;
      return obj;
    })
    this.setState({ libaryWithPositions, orbTexture })
  }

  setTrack(obj, i) {
      this.setObjPosition(obj);
      if (obj.updateColor) {
        obj.updateColor();
      }
      obj.texture= new THREE.TextureLoader(manager).load(obj.jpg);
  }

  setTrackUpdate(obj, i) {
    if (obj.updateColor) {
      obj.updateColor();
    }
    obj.texture= new THREE.TextureLoader(manager).load(obj.jpg);
}

  sendMessage(message) {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify(message));
    }
  }

  receiveMessage(event) {
    if (event.data.type === 'data') {
      if (event.data.key === 'library') {
        var initialArray = event.data.data.map(track => Object.assign({}, track));
        for (let i = 1; i < this.state.libraryMultiply; i++) {
          event.data.data.push(...initialArray.map(track => Object.assign({}, track, {id: `${track.id}-rep${i}`})));
        }
      }
      // else if (event.data.key === 'addTrack') {
      //   let updatedCollection = [...this.state.library, event.data.data];
      //   this.setState({ library: updatedCollection});
      // }
      // else if (event.data.key === 'removeTrack') {
      //   // event.data.data is a trackId in this case
      //   let updatedCollection = this.state.library.filter(track => track.id !== event.data.data);
      //   this.setState({ library: updatedCollection});
      // }
      this.setState({
        [event.data.key]: event.data.data
      })
    }
    if (event.data.type === 'setFollowTarget') {
      followTarget = event.data.data;
    }
    if (event.data.type === 'setDebug') {
      this.debugEnabled = event.data.data;
    }
    if (event.data.type === 'updateSingleTrack') {
      const { id, key, value } = event.data.data;
      this.state.library.find(obj => obj.index === id)[key] = value;
      this.setTrackUpdate(this.state.library[id], id)
    }
    if (event.data.type === 'setPage') {
      setPage(event.data.data);
    }
    const { library, textures } = this.state;
    const shouldReload = event.data.type === 'data' || event.data.type === 'setPage' ;
    if (shouldReload && library.length > 0 && Object.keys(textures).length > 0 && currentPage != null) {
      this.initManager();
      this.loadTextures();
    }
    if (this.debugEnabled) {
      this.sendMessage({
        type: 'debug',
        data: {
          type: 'receiveMessage',
          event: event.data,
        }
      })
    }
  }

  raycastIntersects(touchDetails) {
    const raycaster = new THREE.Raycaster();
    const touch =  {
      x: (  touchDetails.pageX / window.innerWidth ) * 2 - 1,
      y: (  - touchDetails.pageY / window.innerHeight ) * 2 + 1,
    };
    raycaster.setFromCamera(touch, mainCamera );
    return raycaster.intersectObjects( scene.current.children, true );
  }

  untouchLast() {
    if (lastIntersect) {
      lastIntersect.untouch();
      lastIntersect = null;
    }
    if (followTarget) {
      followTarget = null;
    }
  }

  screenToWorld(screenCoordinates) {
    var vec = new THREE.Vector3(); // create once and reuse
    var pos = new THREE.Vector3(); // create once and reuse
    vec.set(
        ( screenCoordinates.x / window.innerWidth ) * 2 - 1,
        - ( screenCoordinates.y / window.innerHeight ) * 2 + 1,
        0 );
    vec.unproject( mainCamera );
    vec.sub( mainCamera.position ).normalize();
    var distance1 = - mainCamera.position.z / vec.z;
    pos.copy( mainCamera.position ).add( vec.multiplyScalar( distance1 ) );
    return pos;
  }

  onTouchStart(e) {
    if (followTarget) {
      followTarget = null;
    }
    if (e.touches.length !== 1) {
      this.untouchLast();
      return;
    }
    this.lastTouchStart = e.touches[0];
    const intersects = this.raycastIntersects(e.touches[0]);
    intersects.forEach(intersect => {
      if (intersect.object.touch) {
        const touchStartLocation = this.screenToWorld(new THREE.Vector2(this.lastTouchStart.screenX, this.lastTouchStart.screenY))
        lastIntersect = intersect.object;
        lastIntersectDeltaPos = new Vector3().subVectors(lastIntersect.data.position, touchStartLocation);
        enableCameraPan = false;
      }
    });
    this.longTouchFired = false;
    this.longTouchTimeout = setTimeout(() => {
      if (lastIntersect) {
        this.longTouchFired = true;
        lastIntersect.onLongTouch();
      }
    }, this.longTouchTime)
  }

  onTouchMove(e) {
    const UNTOUCH_DISTANCE = 20;
    const { touches } = e;
    if (this.lastTouchStart) {
      if (lastIntersect) {
        const touchLocation = this.screenToWorld(new THREE.Vector2(touches[0].screenX, touches[0].screenY))
        lastIntersect.data.position.x = touchLocation.x + lastIntersectDeltaPos.x;
        lastIntersect.data.position.y = touchLocation.y + lastIntersectDeltaPos.y;
      }
      const deltaX = this.lastTouchStart.screenX - touches[0].screenX;
      const deltaY = this.lastTouchStart.screenY - touches[0].screenY;
      const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
      if (distance > UNTOUCH_DISTANCE) {
        this.moveWithinTouch = true;
        if (!currentPageConfig.canMoveTracks) {
          this.untouchLast();
        }
        clearTimeout(this.longTouchTimeout);
      }
    }
  }

  onTouchEnd(e) {
    if (lastIntersect) {
      if (this.moveWithinTouch) {
        const { position } = lastIntersect;
        const { id } = lastIntersect.data;
        this.sendMessage({ type: 'moved-orb', message: { id, position } });
        this.untouchLast();
      }
      if (!this.longTouchFired) {
        // NOTE: Enable if chain swapping needs to occur
        // const intersects = this.raycastIntersects(e.changedTouches[0]);
        // intersects.forEach(intersect => {
        //   if (intersect.object.touch && intersect.object !== lastIntersect) {
        //     this.swapPositions(intersect.object.data, lastIntersect.data)
        //   }
        // });
        if (this.moveWithinTouch) {
          this.moveWithinTouch = false;
        } else {
          lastIntersect.touch();
          lastIntersect = null;
        }
        clearTimeout(this.longTouchTimeout);
      }
    }
    enableCameraPan = true;
  }

  swapPositions(obj1, obj2) {
    const { libaryWithPositions } = this.state;
    const obj1Index = libaryWithPositions.findIndex(track => track === obj1);
    const obj2Index = libaryWithPositions.findIndex(track => track === obj2);
    const sameOrder = obj2Index > obj1Index;
    const temp = libaryWithPositions.splice(obj2Index, 1)
    libaryWithPositions.splice(obj1Index, 0, ...temp)
    for (let index = obj1Index; sameOrder ? index <= obj2Index : index >= obj2Index; sameOrder ? index++ : index--) {
      this.setObjPosition(libaryWithPositions[index], { generateIndex: true });
    }
  }

  render() {
    const { loaded, libaryWithPositions, orbTexture } = this.state;
    if (!loaded) {
      return (<Canvas className="canvas"></Canvas>)
    }
    return (
      <>
        <Canvas
          className="canvas"
          camera={{ position: [0, 5, 0]}}
          pixelRatio={2}
          onTouchStart={(e) => this.onTouchStart(e)}
          onTouchMove={(e) => this.onTouchMove(e)}
          onTouchEnd={(e) => this.onTouchEnd(e)}
        >
          <Main>
            {libaryWithPositions.map((obj, i) => {
              return <Track
                key={`track-${i}`}
                position={obj.position}
                size={obj.size}
                color={obj.color}
                rotation={obj.rotation}
                texture={obj.texture}
                mask={obj.mask}
                data={obj}
              />
            })}
            {libaryWithPositions.filter(obj => obj.luminescence === false).map((obj, i) => {
              return <WaterBubblePlane
                key={`track-${i}`}
                position={obj.position}
                size={obj.size}
                rotation={obj.rotation}
                data={obj}
                orbTexture={orbTexture}
              />
            })}
            <ambientLight />
            <pointLight distance={1000} intensity={30} color="white" />
            <Particles count={500} />
            <Sparks count={10} colors={['#A2CCB6', '#FCEEB5', '#EE786E', '#e0feff', 'lightpink', 'lightblue']} />
            <Controls
              enableRotate={false}
              minDistance={5}
              maxDistance={20}
              enableDamping
              dampingFactor={0.1}
              rotateSpeed={1}
              maxPolarAngle={Math.PI / 2}
              minPolarAngle={Math.PI / 2}
              touches={{
                ONE: TOUCH.PAN,
                TWO: TOUCH.DOLLY_PAN,
              }}
              screenSpacePanning={true}
            />
          </Main>
          <Bloom>
            <ambientLight />
            <pointLight distance={1000} intensity={30} color="white" />
            {libaryWithPositions.filter(obj => obj.luminescence !== false).map((obj, i) => {
              return <WaterBubblePlane
                key={`track-${i}`}
                position={obj.position}
                size={obj.size}
                rotation={obj.rotation}
                data={obj}
                orbTexture={orbTexture}
              />
            })}
          </Bloom>
          {/* <Effects /> */}
        </Canvas>
      </>
    );
  }
}

export default App;
