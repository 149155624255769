import React, { useRef, useEffect, useState } from 'react'
import { PlainAnimator } from 'three-plain-animator/lib/plain-animator';
import { useFrame } from 'react-three-fiber'
import { moveRef } from '../App';

export default function WaterBubblePlane({ position, size, rotation, orbTexture, data }) {
  const ref = useRef()
  const [texture, setTexture] = useState();
  const [color, setColor] = useState();
  useEffect(() => {
    data.updateColor = () => {
      setColor(data.color);
      setTexture(orbTexture);
    };
    const animate = () => {
      animator.animate();
      requestAnimationFrame(animate)
    }
    data.updateColor()
    const animator = new PlainAnimator(orbTexture, 5, 18, 5*18, 20);
    animator.init()
    animate();
  }, [texture, setTexture, orbTexture, setColor, data]);
  useFrame(() => {
    moveRef(ref, position)
  })

  return (
    <mesh ref={ref} position={position} rotation={[0, 0, rotation]}>
      <planeGeometry attach="geometry" args={[1.5 * size, 1.5 * size, 1.5 * size]} />
      <meshBasicMaterial transparent={true} opacity={1} attach="material" color={color} map={texture} />
    </mesh>
  )
}
